.course_Avatar {
display: flex;
align-items: center;
width: 70px;
height: 70px;
border-radius: 100%;
padding: 3px;
background: conic-gradient(from 150.82deg at 50% 50%, #E543FF 0deg, #9850FF 108.75deg, #FF406E 210deg, #2663FF 290.63deg, #E543FF 360deg);


box-shadow: var(--default-style-shadow);
}

.course_Avatar Img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    border-radius: 100%;
  }