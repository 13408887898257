.defaultWrapper {
    /* composes: defaultStyle from 'src/style.module.css'; */

    width: 100%;
    box-shadow: none;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 11px;
    text-align: center;

}

.defaultWrapper>button {
    width: 100%;
    height: 100%;
}

.defaultWrapper>form {
    width: 100%;
}