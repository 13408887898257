.account_Avatar {
  display: flex;
  height: auto;
  align-items: center;
  border-radius: 50%;
  /* padding: 3px; */
  /* background: conic-gradient(from 150.82deg at 50% 50%, #E543FF 0deg, #9850FF 108.75deg, #FF406E 210deg, #2663FF 290.63deg, #E543FF 360deg); */


  /* box-shadow: var(--default-style-shadow); */



  border: 1px solid #E6E6E6;
  /* box-shadow: 0px 0.72857px 7.28571px 0px rgba(0, 0, 0, 0.25); */
}

.account_Avatar Img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  border-radius: 100%;
}

.account_Avatar:hover Img{ background-color: red;} 