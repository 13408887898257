
.dialogAvatar {
    width: min-content;
    height: min-content;
    position: relative;
    /* padding: 12px 0 12px 12px; */
}

.dialogPhoto {
    border-radius: 50%;
}

.dialogPhoto img {
    object-fit: cover;
    object-position: center;
    border-radius: 100%;
}

.statusUser.online {
    background-color: #266cef;
}

.statusUser.offline {
    background-color: #828a93;
    /* border: 1px solid #828a93; */
}